import React from 'react';
import { Link } from 'gatsby';
import c from 'classnames';

import Layout from 'components/layout';
import wrappers from 'styles/_wrappers.module.scss';
import helpers from 'styles/_helpers.module.scss';
import styles from 'styles/pages/404.module.scss';
import landingStyles from 'styles/pages/landing.module.scss';

import underConstruction from 'assets/images/under_construction.png';

const NotFoundPage = props => (
  <Layout location={props.location}>
    <div className={c(wrappers.wrapper, styles.wrapper)}>
      <div className={c(styles.itemLeft, helpers.mb30)}>
        <h1 className={c(helpers.mb30, helpers.mt90, styles.itemLeft)}>
          Erro 404,
          <br></br>
          Página não encontrada.
        </h1>
        <p>Não foi possível encontrar a página que procura.</p>
        <Link to="/" className={c(helpers.mt24,landingStyles.btn)}>voltar a casa</Link>
      </div>

      <div className={c(styles.itemRight)}>
        <img src={underConstruction} className={c(styles.image)} alt="" />
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
